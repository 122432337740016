import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'


import consulting from '../images/services/new/consulting.jpg'
import virtualization from '../images/services/new/virtualization.jpg'
import cloud from '../images/services/new/cloud.jpg'
import backup from '../images/services/new/backup.jpg'


import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import infrastructure from '../images/services/new/infrastructure.jpg'
import network from '../images/services/new/pragicts-itops-networking.jpg'
import server from '../images/services/new/pragicts-itops-servers.jpg'
import planning from '../images/services/new/pragicts-itops-capacity-plannig.jpg'
import databackup from '../images/services/new/pragicts-itops-backup-and-recovery.jpg'
import patch from '../images/services/new/pragicts-itops-patch-management.jpg'
import support from '../images/services/new/pragicts-itops-it-support.jpg'
import storage from '../images/services/new/pragicts-itops-storage.jpg'
import asset from '../images/services/new/pragicts-itops-it-asset-management.jpg'


const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="PragICTS | ITOps | Services">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2>SERVICES</h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section className="dark-bg no-padding">
                        <div className="hidden-info-wrap-bg">
                            <div className="bg-ser">
                            </div>
                            <div className="overlay"></div>
                        </div>
                        <div className="hidden-info-wrap">
                            <div className="hidden-info fl-wrap">
                                <div className="hidden-works-list fl-wrap">
                                   
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={infrastructure}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>IT Infrastructure Implementation and Management</h3>
                                                <p>In the contemporary landscape, IT infrastructure presents a diverse spectrum of implementations, ranging from traditional on-premise hardware setups to the expansive realm of cloud services, encompassing a myriad of technologies, each with its own set of advantages and limitations.</p>
                                                <p>PragICTS specializes in offering comprehensive services covering the entire spectrum of IT infrastructure needs, including design, implementation, management, and support. Our expertise extends across various components such as servers, storage systems, network infrastructure, and seamlessly integrates with cloud solutions. We aim to ensure consistent performance, robust security measures, scalable architecture, and uninterrupted availability for your operations.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">01.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={network}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Network Implementation and Management</h3>
                                                <p>An impeccably designed, implemented, and managed network infrastructure is the cornerstone of delivering a seamless user experience, optimal performance, and robust security measures.
</p>
                                                <p>PragICTS offers a comprehensive suite of services covering all aspects of network infrastructure, including design, implementation, management, and ongoing support. Our expertise encompasses a wide range of network devices, including routers, switches, firewalls, and other essential components. Additionally, we extend our capabilities to encompass software-based solutions and functionalities across various cloud services, including the Secure Access Service Edge (SASE) stack.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">02.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={server}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Server Implementation and Management</h3>
                                                <p>Servers play a pivotal role in corporate environments by furnishing centralized computing resources such as storage, processing power, networking capabilities, and security features.</p>
                                                <p>PragICTS specializes in delivering a comprehensive range of services encompassing the design, implementation, management, and ongoing support of IT and rack servers. Our expertise extends to both Linux variants and Microsoft Windows Servers, along with their associated components, including operating systems and server software. We ensure that your server infrastructure is meticulously designed, seamlessly implemented, efficiently managed, and consistently supported to meet your organization's specific needs and objectives.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">03.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={storage}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Data Storage Implementation and Management</h3>
                                                <p>Data storage encompasses a spectrum of methods and technologies aimed at capturing and preserving digital information effectively.</p>
                                                <p>PragICTS offers a comprehensive suite of services covering the design, implementation, management, and support of storage solutions. This includes Storage Area Network (SAN) setups, Network-Attached Storage (NAS) systems, as well as cloud-based options such as Egnyte and others. Our focus is on optimizing throughput, scalability, and ensuring high availability, thereby enabling your organization to store and access data efficiently and reliably.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">04.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={databackup}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Data Backup and Recovery </h3>
                                                <p>The essence of backups lies in creating redundant copies of data that can be retrieved in the event of primary data failure. Such failures may stem from hardware or software malfunctions, data corruption, or human-induced errors like accidental deletion or malicious attacks such as viruses or malware. These backup copies serve as a means to restore data to a previous state, facilitating business recovery from unforeseen disruptions.</p>
                                                <p>Crucially, storing backup data on a separate medium is imperative to shield against primary data loss or corruption. This secondary medium can range from simple external drives or USB sticks to more robust options like disk storage systems, cloud storage containers, or tape drives. Additionally, storing backups at a remote location mitigates risks associated with localized events such as weather-related disasters.
</p>
<p>PragICTS offers comprehensive services encompassing the design, implementation, management, and support of data backup and disaster recovery solutions. Our objective is to ensure the protection, security, and availability of your data. Leveraging tools, standards, processes, and automation, we strive to deliver consistent and seamless implementations tailored to your organization's needs.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">05.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={patch}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Patch Management</h3>
                                                <p>Patch management is a critical process involving the application of updates to software, drivers, and firmware to safeguard against vulnerabilities. Beyond security, effective patch management also contributes to optimized system performance, thereby enhancing productivity. PragICTS offers comprehensive services aimed at ensuring the efficient management and application of patches. Our approach encompasses the entire process, from centralized monitoring to automation, to guarantee swift and effective protection against emerging threats.</p>
                                                <p>System monitoring and performance tuning are indispensable for the seamless operation of any business. In today's landscape, downtime is simply not an option. Real-time monitoring of the entire IT infrastructure, coupled with parameterized alerts, is imperative to maintain an always-available and high-quality IT environment.</p>
                                                <p>PragICTS introduces the Sentinel Platform <a class="codered" href='www.sentinel.pragicts.com' target='_blank'>(www.sentinel.pragicts.com)</a>, a centralized solution designed for real-time monitoring of your complete IT infrastructure. By leveraging Sentinel, organizations can proactively address issues, enabling preventive maintenance and minimizing, if not eliminating, downtime altogether.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">06.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={support}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>IT Support (L1, L2, L3)</h3>
                                                <p>At its core, IT support entails providing assistance to employees and the broader organization with technology-related challenges. Its primary objective is to furnish users with solutions to the issues they encounter. However, within a business setting, IT support extends beyond troubleshooting to encompass activities such as equipment setup, installation, configuration, and more. Business IT support encompasses optimizing network performance and fortifying defenses against cyber threats. Through comprehensive IT support and services, an organization can unlock the full potential of its network infrastructure while adhering to industry best practices for safeguarding digital assets.</p>
                                                <p>PragICTS offers a range of IT support services, spanning across L1, L2, and L3 levels, to ensure consistent quality in IT operations. Leveraging best practices, automation, standards, tools, knowledge bases, and expertise, we empower organizations to maintain operational excellence and effectively navigate the complexities of modern technology landscapes.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">07.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={backup}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>User Account Management</h3>
                                                <p>User management has long been a cornerstone within the realms of Identity Access Management (IAM) and IT Asset Management (ITAM). However, its significance has been revitalized with the advent of cloud-based subscriptions. Recent trends indicate a clear migration towards cloud-based IAM solutions, offering administrators enhanced access and control over digital asset management.</p>
                                                <p>PragICTS offers a comprehensive suite of services encompassing the design, implementation, management, and support of user accounts, policies, permissions, and access controls. Our aim is to fortify IT systems against unauthorized access while ensuring efficient management and control of user interactions. By leveraging our expertise, organizations can safeguard their digital assets and maintain robust security measures in an evolving technological landscape.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">08.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={asset}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>IT Asset Management (ITAM) </h3>
                                                <p>IT Asset Management (ITAM) is the systematic process of overseeing an organization's assets, ensuring they are properly accounted for, deployed, maintained, upgraded, and ultimately disposed of when necessary. In essence, ITAM involves tracking and managing both tangible and intangible assets to maximize their value and utility within the organization. </p>
                                                <p>PragICTS offers a complete range of services encompassing the design, implementation, management, and support of ITAM initiatives. Our approach is comprehensive and holistic, aiming to provide organizations with robust solutions for optimizing asset utilization, minimizing costs, and ensuring compliance with regulatory requirements. With our expertise, organizations can effectively manage their IT assets throughout their lifecycle, from acquisition to disposal.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">09.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={planning}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Capacity Planning & Future Proofing </h3>
                                                <p>The IT capacity plan serves as a blueprint for determining the resources required to fulfill an organization's service demands effectively. Without proper capacity planning, organizations risk encountering performance issues, overspending on hardware, and dissatisfaction among users.</p>
                                                <p>PragICTS offers a comprehensive suite of services encompassing strategy development, implementation, management, and support for IT capacity planning. Our approach aligns closely with business objectives and strategic planning, ensuring that IT resources are optimally utilized and managed to meet organizational needs efficiently. By leveraging our expertise, organizations can achieve an optimum and high-quality implementation and management of IT resources, facilitating seamless operations and alignment with business goals.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">10.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={networking}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>IT Security </h3>
                                                <p>IT security encompasses a comprehensive set of strategies, measures, and controls aimed at safeguarding the entirety of the IT infrastructure. At its core, IT security strives to achieve three fundamental goals: preserving the confidentiality, integrity, and availability of information and systems.</p>
                                                <p>PragICTS offers a range of services focused on designing, implementing, managing, and supporting holistic security solutions. Our approach addresses security concerns at both macro and micro levels, spanning across all surfaces, platforms, and devices within the IT landscape. By leveraging our expertise, organizations can ensure secure operations and protect against evolving threats effectively.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">11.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={virtualization}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Information Technology Infrastructure Library (ITIL) </h3>
                                                <p>The Information Technology Infrastructure Library (ITIL) serves as a comprehensive set of best practices and framework for managing IT activities, including IT service management (ITSM) and IT asset management (ITAM). ITIL is centered around aligning IT services with the business objectives of an organization. It outlines standardized processes, procedures, tasks, and checklists that are not tied to any specific organization or technology, providing a baseline for IT operations. ITIL enables organizations to demonstrate compliance with industry standards and measure improvements in their IT service delivery.</p>
                                                <p>PragICTS offers a range of services encompassing the design, implementation, management, and support of ITIL practices. Leveraging our expertise, organizations can effectively adopt and tailor ITIL principles to suit their specific needs, thereby enhancing the alignment of IT services with business goals and improving overall operational efficiency.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">12.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={cloud}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Security Information and Event Management (SIEM) </h3>
                                                <p>Security Information and Event Management (SIEM) technology plays a crucial role in supporting threat detection, compliance adherence, and security incident management. It achieves this by collecting and analyzing security events, both in near real-time and historically, along with a diverse range of contextual data sources. The key capabilities of SIEM include comprehensive log event collection and management, analysis of events and data from disparate sources, and operational functionalities such as incident management, dashboards, and reporting. </p>
                                                <p>PragICTS offers a suite of SIEM services encompassing design, implementation, management, and support. Leveraging our expertise, organizations can effectively deploy and utilize SIEM technology to enhance their security posture, detect and respond to threats efficiently, and ensure compliance with regulatory requirements.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">13.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Identity and Access Management (IAM) </h3>
                                                <p>Identity management (IdM), alternatively referred to as identity and access management (IAM or IdAM), constitutes a framework comprising policies and technologies aimed at guaranteeing that authorized users within an enterprise ecosystem possess the requisite access to technology resources. IdM systems operate within the broader domains of IT security and data management. These systems are responsible not only for identifying, authenticating, and regulating access for individuals utilizing IT resources but also for managing access to the hardware and applications required by employees.</p>
                                                <p>PragICTS offers comprehensive IAM services, covering design, implementation, management, and support. Leveraging our expertise, organizations can establish robust identity and access management frameworks to ensure secure access to resources, maintain regulatory compliance, and mitigate the risks associated with unauthorized access.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">14.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={performance}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Security Policy Development </h3>
                                                <p>In today's intricate IT landscape, characterized by a myriad of technologies sourced from various origins, effective and meaningful security cannot be left to chance or happenstance. It necessitates a deliberate and cohesive approach, underpinned by a well-defined policy framework. This framework should encompass a wide array of policies touching upon areas such as Change Management, Equipment Disposal, Mobile Device (BYOD), Network Distribution, Remote Access, Router and Switch Security, Server Security, Software Installation, Software Licensing, System Account Configuration, Vulnerability Management, Wireless Configuration, Workstation Configuration, and more. </p>
                                                <p>PragICTS offers a comprehensive suite of services tailored to define, implement, manage, and support the continuous maintenance of IT and security policies. Leveraging our expertise, organizations can establish robust policy frameworks that ensure the effective well-being of corporate IT infrastructure. By aligning security policies with business objectives and industry best practices, we enable organizations to enhance their security posture, mitigate risks, and safeguard their digital assets against evolving threats.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">15.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={rationalizaion}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Penetration Testing </h3>
                                                <p>Penetration testing (pen testing) involves conducting authorized simulated attacks on a computer system to assess its security posture. Penetration testers employ the same tools, techniques, and procedures as malicious attackers to identify and demonstrate the potential business impacts of vulnerabilities in the system. These tests typically simulate various types of attacks that could pose a threat to a business. They assess the system's resilience against attacks from both authenticated and unauthenticated positions, as well as from a variety of system roles. With a properly defined scope, a penetration test can delve into any aspect of a system. </p>
                                                <p>PragICTS offers comprehensive services for executing controlled, ethical hacking tests to identify vulnerabilities and weaknesses in an organization's systems and networks. Leveraging our expertise, organizations can proactively identify and address security gaps, thereby enhancing their overall security posture and mitigating potential risks.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">16.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={backup}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Security orchestration, automation and response (SOAR)</h3>
                                                <p>Security Orchestration, Automation, and Response (SOAR) represents a suite of cybersecurity technologies that empower organizations to automate responses to certain incidents. By aggregating inputs from various sources such as alerts from the SIEM (Security Information and Event Management) system, Threat Intelligence Platform (TIP), and other security technologies, SOAR platforms aid in defining, prioritizing, and executing standardized incident response activities. Organizations leverage SOAR to enhance the efficiency of both physical and digital security operations, enabling administrators to address security alerts swiftly and without manual intervention. Depending on the nature of a security event detected by network tools, SOAR can either alert administrators or initiate predefined actions autonomously.
</p>
                                                <p>PragICTS offers comprehensive services covering the implementation and management of SOAR solutions to enhance security management effectively. Leveraging our expertise in cybersecurity, organizations can streamline incident response processes, improve operational efficiency, and bolster their overall security posture.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">17.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={support}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Endpoint Security</h3>
                                                <p>Endpoint security, also known as endpoint protection, refers to a strategy for safeguarding computer networks that are remotely connected to client devices. The proliferation of endpoint devices such as laptops, tablets, mobile phones, Internet-of-Things (IoT) devices, and other wireless devices connected to corporate networks creates avenues for security threats. Endpoint security aims to enforce a certain level of compliance with standards on these devices to mitigate risks. 
</p>
                                                <p>Over the years, the landscape of endpoint security has evolved significantly. It has transitioned from traditional antivirus software to encompass a more sophisticated and comprehensive defense approach. This modern approach includes next-generation antivirus solutions, threat detection, investigation, and response capabilities, device management functionalities, data leak protection (DLP), and other measures to address evolving threats. The goal is to secure individual devices, including computers, smartphones, and IoT devices, from malware and other security threats.</p>
                                                <p>PragICTS offers services designed to effectively protect endpoint devices by implementing, managing, and supporting cohesive security solutions. Leveraging global tier 1 products in this category, we ensure robust protection against a wide range of security threats, thereby enhancing the security posture of organizations' endpoint environments.

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">18.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                    
                                    <a  href='https://automation.pragicts.com' target='_black'>  <div className="hidden-works-item-text">
                                                <h3>IT Automation</h3>
                                                <p>IT automation involves developing software and systems to automate repetitive processes and minimize manual intervention. By replacing manual tasks with automated workflows, IT automation streamlines the delivery of IT infrastructure and applications, including those hosted in the cloud. This automation eliminates the need for human involvement in tasks that were previously performed manually, leading to a reduction in errors and an increase in throughput. Overall, IT automation enables organizations to expedite the deployment and management of IT resources, ultimately improving efficiency and productivity.
</p>
                                                <p>PragICTS offers comprehensive services aimed at designing, implementing, managing, and supporting automation solutions across the entire IT landscape. Leveraging our expertise, organizations can streamline processes, enhance operational efficiency, and accelerate the delivery of IT infrastructure and applications. Whether it's automating routine tasks, deploying cloud resources, or optimizing workflows, PragICTS provides tailored automation solutions to meet the specific needs and objectives of our clients. With our support, organizations can unlock the full potential of automation to drive innovation and business growth. For fuller details refer to <a class="codered" href="https://automation.pragicts.com" target='_blank'>(https://automation.pragicts.com)</a>

</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number">19.</span>
                                        
                                            </div></a>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={networking}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Microsoft Systems Center Configuration Manager (MSCCM) </h3>
                                                <p>Microsoft System Center Configuration Manager (SCCM) is indeed a robust management solution tailored by Microsoft to facilitate the deployment, management, and security of devices and applications across the entire IT infrastructure. Its functionalities include:</p>
                                         
                                                <div className="clearfix"></div>
                                                <span className="serv-number">20.</span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={virtualization}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Centralized Management</h3>
                                                <p>SCCM provides a centralized console for managing various aspects of IT infrastructure, including servers, desktops, laptops, and mobile devices. This centralization streamlines administrative tasks and simplifies the management of a large number of devices.
</p>
                                        
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={cloud}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Software Deployment</h3>
                                                <p>SCCM facilitates automated software deployment and updates. IT administrators can deploy applications and updates to devices across the organization without requiring manual intervention on each machine, saving time and reducing errors.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Operating System Deployment (OSD)</h3>
                                                <p>OSD in SCCM allows for the automated deployment of operating systems to new or existing devices. This is particularly useful for large-scale deployments, system upgrades, or when provisioning new hardware.</p>
                                          
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={performance}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Patch Management</h3>
                                                <p>SCCM helps in managing and deploying patches and updates to ensure that software and operating systems are up-to-date and secure. It allows administrators to schedule and automate patch deployment across the organization.</p>
                                               
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={rationalizaion}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Inventory Management</h3>
                                                <p>SCCM provides detailed hardware and software inventory capabilities. IT administrators can gather information about the hardware and software configurations of devices in the network, aiding in better asset management and compliance tracking.</p>
                                       
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={backup}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Endpoint Protection</h3>
                                                <p>SCCM includes features for endpoint protection, allowing administrators to deploy and manage antimalware policies across the organization. It helps in safeguarding devices from malicious software and threats.
</p>
                                           
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={support}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Role-Based Administration</h3>
                                                <p>Role-based access control in SCCM enables administrators to assign specific roles and permissions to users based on their responsibilities. This enhances security and ensures that only authorized personnel have access to sensitive tasks and data.</p>
                                            
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={consulting}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Reporting and Compliance</h3>
                                                <p>SCCM offers robust reporting capabilities, allowing administrators to generate reports on various aspects of the IT environment, such as software compliance, hardware inventory, and deployment status. These reports aid in decision-making and compliance monitoring.</p>
                                             
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div> <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={networking}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Integration with Active Directory</h3>
                                                <p>SCCM seamlessly integrates with Microsoft Active Directory, leveraging its organizational structure for simplified device management and user authentication.</p>
                                            
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={virtualization}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Power Management</h3>
                                                <p>SCCM includes power management features that enable organizations to implement power-saving policies for devices. This can help reduce energy consumption and contribute to cost savings.</p>

                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={cloud}>
                                    
                                            <div className="hidden-works-item-text">
                                                <h3>Application Virtualization</h3>
                                                <p>SCCM supports application virtualization technologies, allowing for the creation and deployment of virtualized applications, reducing conflicts between applications, and providing more flexibility in software deployment.</p>
                                             
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                       
                                    </div>
                                    <div className="hidden-works-item  serv-works-item fl-wrap" data-bgscr={security}>
                                       
                                            <div className="hidden-works-item-text">
                                                <h3>Mobile Device Management (MDM)</h3>
                                                <p>SCCM integrates with Microsoft Intune to provide MDM capabilities. This allows organizations to manage and secure mobile devices, ensuring consistency in device configuration and compliance.</p>
                                          <p>PragICTS provides design, implementation, management, and support for the Microsoft System Center Configuration Manager (SCCM) platform.</p>
                                                <div className="clearfix"></div>
                                                <span className="serv-number"></span>
                                        
                                            </div>
                                    
                                    </div>
                                   









                                </div>
                            </div>
                        </div>
                        <div className="fl-wrap limit-box"></div>
                    </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
